.navbar {
  width: 100%;
  background-color: #333;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbarLogo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
}

.navbarMenu {
  display: flex;
}

.navbarMenu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbarMenu ul li {
  margin: 0 15px;
}

.navbarMenu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}

.navbarToggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
}

.open {
  max-height: 100vh;
}

@media screen and (max-width: 768px) {
  .navbarMenu {
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: #333;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: max-height 0.3s ease;
    overflow: hidden;
    max-height: 0;
  }

  .navbarMenu.open {
    max-height: 100vh;
  }

  .navbarMenu ul {
    flex-direction: column;
  }

  .navbarMenu ul li {
    margin: 15px 0;
  }

  .navbarToggle {
    display: flex;
  }
}
.newProject{
  color: #fff;
}