.tableContainer {
  width: 100%;
  overflow-x: auto;

  margin: 20px 0;
  margin-top: 80px;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.dataTable th,
.dataTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.dataTable th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}

.dataTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dataTable tr:hover {
  background-color: #f1f1f1;
}

.dataTable th,
.dataTable td {
  text-align: center;
  padding: 12px 15px;
}

.dataTable th {
  background-color: #4CAF50;
  color: white;
}
.transactionButton{
  background-color: #af4c4c;
  color: white;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

}
@media screen and (max-width: 768px) {
  .dataTable th,
  .dataTable td {
    padding: 10px;
  }
}